@font-face{
  font-family: 'Cairo';
  src: url('/src/assets/fonts/Cairo-Regular.ttf');
}
body{
  font-family: 'Cairo', sans-serif !important;
}
.pointer{
  cursor: pointer;
}
.fs-7{
  font-size: 0.9rem;
}
.cursor{
  cursor: context-menu;
}
.position{
  top: 98%;
}
.dropdown-hover{
  height: 110px;
}
.dropdown-hover > div{
  cursor: context-menu;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-bottom: 3px;
}
.dropdown-hover > div:hover{
  background-color: rgb(35, 35, 255);
  color: white;
}
.floating-area{
  height: 200px !important;
}
.wrap{
  white-space: pre-line;
}
.form-floating.form-floating-ar>label{
  top: 0;
  right: 0;
  transform-origin: 100% 0;
  transition: opacity .1s ease-in-out,transform .1s ease-in-out;
}
.form-floating.form-floating-ar>.form-control-plaintext~label, .form-floating.form-floating-ar>.form-control:focus~label, 
.form-floating>.form-control:not(:placeholder-shown)~label, .form-floating>.form-select~label{
  opacity: .65;
  transform: scale(.85) translateY(-0.5rem) translateX(-0.15rem);
}
.form-select.form-select-ar{
  background-position: left 0.75rem center;
}
.style-dropdown{
  width: 96%;
  margin-top: 1px;
}
.position-select{
  top: 98%;
}
.dropdown-hover-select > div:hover{
  background-color: rgb(35, 35, 255);
  color: white;
}
.centered{
  height: 100vh;
}
.PhoneInput>input{
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  appearance: none;
  background-color: var(--bs-body-bg);
  background-clip: padding-box;
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.header-section h3::before{
  content: "";
  position: absolute;
  display: block;
  bottom: 1px;
  left: calc(50% - 60px);
  width: 120px;
  height: 1px;
  background: #ddd;
}
.header-section h3::after{
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: calc(50% - 20px);
  width: 40px;
  height: 3px;
  background: #47b2e4;
}
.font-icon-color{
  color: #47b2e4;
}
.accordion-button-ar::after{
  width: 0 !important;
}
.card-header-color{
  color: #212560 !important;
}
.card-animation{
  transition: 0.6s;
}
.card-animation:hover{
  margin-top: -10px;
  transition: 0.6s;
}
.card-position{
  position: absolute !important;
  top: 65% !important;
  border: none !important;
}
.card-div-position{
  position: absolute !important;
  top: 65% !important;
  z-index: 1;
  background-color: rgba(255, 255, 255, 0.90);
}
.font-service-style{
  border: 4px solid white;
  border-radius: 50%;
  margin-top: -2.4rem;
  background-color: #0EA2BD;
  color: white;
  transition: 0.8s;
}
.div-card-hover{
  overflow: hidden;
}
.div-card-hover h5{
  transition: 0.8s;
}
.div-card-hover img{
  transition: 0.8s;
}
.div-card-hover:hover .font-service-style{
  border-color: #0EA2BD;
  background-color: white;
  color: #0EA2BD;
  transition: 0.8s;
}
.div-card-hover:hover h5{
  color: #0EA2BD;
  transition: 0.8s;
}
.div-card-hover:hover img{
  transform: scale(1.1);
  transition: 0.8s;
}
.header-margin{
  margin-top: 1.5rem;
}
.h-img{
  height: 42vh;
}
.dot-pulse{
  position: relative;
  left: -9999px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #212529;
  color: #212529;
  box-shadow: 9999px 0 0 -5px;
  animation: dot-pulse 1.5s infinite linear;
  animation-delay: 0.25s;
}
.dot-pulse::before, .dot-pulse::after{
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #212529;
  color: #212529;
}
.dot-pulse::before{
  box-shadow: 9984px 0 0 -5px;
  animation: dot-pulse-before 1.5s infinite linear;
  animation-delay: 0s;
}
.dot-pulse::after{
  box-shadow: 10014px 0 0 -5px;
  animation: dot-pulse-after 1.5s infinite linear;
  animation-delay: 0.5s;
}
.anim{
  margin-left: 28px;
}
.anim-ar{
  margin-right: 28px;
}
@keyframes dot-pulse-before{
  0%{
    box-shadow: 9984px 0 0 -5px;
  }
  30%{
    box-shadow: 9984px 0 0 2px;
  }
  60%, 100%{
    box-shadow: 9984px 0 0 -5px;
  }
}
@keyframes dot-pulse{
  0%{
    box-shadow: 9999px 0 0 -5px;
  }
  30%{
    box-shadow: 9999px 0 0 2px;
  }
  60%, 100%{
    box-shadow: 9999px 0 0 -5px;
  }
}
@keyframes dot-pulse-after{
  0%{
    box-shadow: 10014px 0 0 -5px;
  }
  30%{
    box-shadow: 10014px 0 0 2px;
  }
  60%, 100%{
    box-shadow: 10014px 0 0 -5px;
  }
}
.img-size{
  width: 150px;
}
.brand-hover{
  transition: 0.4s;
}
.brand-hover:hover{
  background-color: #0EA2BD;
  color: white;
  transition: 0.4s;
}
.text-color{
  color: #0EA2BD;
}
.socials > a{
  margin-left: 15px;
}
.overlay{
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: .5s ease;
  background: rgb(0,0,0);
  background: linear-gradient(331deg, rgba(0,0,0,1) 0%, rgba(9,53,121,1) 52%);
}
.overlay:hover{
  opacity: 1;
}
.footer-style{
  background-color: #485664;
}
.hr-style{
  color: #0EA2BD;
  width: 10%;
  border-bottom: 2px solid #0EA2BD;
}
.fs-8{
  font-size: 0.7rem;
}
.lower-footer-style{
  background-color: #3A4753;
}
html{
  scroll-behavior: smooth;
}
.m-btn{
  margin-bottom: 30px;
}
.text-fade{
  animation-duration: 2s;
  animation-name: fade;
}
@keyframes fade{
  from{
      opacity: 0
  }
  to{
      opacity: 1;
  }
}
.fade-in{
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 1.5s ease, transform 1.5s ease;
}
.fade-in_about{
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 1.5s ease, transform 1.5s ease;
}
.fade-in-visible{
  opacity: 1;
  transform: translateY(0);
}
.slide-in{
  opacity: 0;
  transform: translateX(-50px);
  transition: opacity 1.5s ease, transform 1.5s ease;
}
.slide-in_project{
  opacity: 0;
  transform: translateX(-50px);
  transition: opacity 1.5s ease, transform 1.5s ease;
}
.slide-in_hire{
  opacity: 0;
  transform: translateX(-50px);
  transition: opacity 1.5s ease, transform 1.5s ease;
}
.slide-in_contact{
  opacity: 0;
  transform: translateX(-50px);
  transition: opacity 1.5s ease, transform 1.5s ease;
}
.slide-in-visible{
  opacity: 1;
  transform: translateX(0);
}
.w-title{
  width: 51%;
}
ul.bullet{
  list-style-type: none;
}
.m-btn{
  margin-top: 120px;
}