/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px){
    .w-nav{
        width: 30%;
    }
    .font-service-style{
        width: 23%;
    }
    .card-height{
        height: 43vh;
    }
    .card-margin{
        margin-bottom: 14rem;
    }
    .display-dot{
        display: none;
    }
    .banner{
        height: 93vh;
    }
    .h_card{
        height: 370px;
    }
    .col-xs-3{
        width: 25% !important;
    }
    .col-xs-9{
        width: 75% !important;
    }
    .margin_backend_ar{
        margin-left: 1px;
    }
}
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px){
    .w-nav{
        width: 30%;
    }
    .font-service-style{
        width: 20%;
    }
    .card-height{
        height: 40vh;
    }
    .card-margin{
        margin-bottom: 13rem;
    }
    .display-dot{
        display: none;
    }
    .banner{
        height: 93vh;
    }
    .h_card{
        height: 370px;
    }
    .margin_backend_ar{
        margin-right: 1px;
    }
}
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px){
    .font-service-style{
        width: 26%;
    }
    .card-height{
        height: 39vh;
    }
    .card-margin{
        margin-bottom: 15rem;
    }
    .display-dot{
        display: block;
    }
    .banner{
        height: 95vh;
    }
    .h_card{
        height: 370px;
    }
    .margin_backend_ar{
        margin-left: 7px;
    }
}
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px){
    .w-nav{
        width: 15%;
    }
    .font-service-style{
        width: 33%;
    }
    .card-height{
        height: 45vh;
    }
    .card-margin{
        margin-bottom: 15rem;
    }
    .banner{
        height: 100vh;
    }
    .h_card{
        height: 420px;
    }
}
/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px){
    .w-nav{
        width: 15%;
    }
    .font-service-style{
        width: 23%;
    }
    .card-height{
        height: 45vh;
    }
    .card-margin{
        margin-bottom: 15.5rem;
    }
    .banner{
        height: 100vh;
    }
    .h_card{
        height: 320px;
    }
}